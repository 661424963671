import React, { useEffect, useState } from 'react';
import { embedDashboard } from "@superset-ui/embedded-sdk";
import { observer } from 'mobx-react-lite'
export const SupersetDashboard = observer(function SupersetDashboard(props) {

    // const height = ((window.innerHeight * 90) / 100).toString()
    const {height, widget} = props
    const width = ((window.innerWidth * 80) / 100).toString()
    const iframe_style = "height:"+height+"px"+";width:"+width+"px"
    const enabled = widget.isEnabled()
    const {screen} = props

    useEffect(() => {

        if(enabled && document.getElementById("superset_container")){
            // embedSuperset()
            widget.embedSupersetDashboard(document.getElementById("superset_container"), iframe_style)
        }
        

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    // async function embedSuperset(){
    //     const res = await embedDashboard({
    //         // id: "ebeba8f8-3b9b-4b71-bd93-a7d6a7071438", // given by the Superset embedding UI
    //         id: widget.uuid,
    //         supersetDomain: "http://192.168.0.106:8088",
    //         mountPoint: document.getElementById("superset_container"), // any html element that can contain an iframe
    //         fetchGuestToken: () => widget.getGuestToken(),
    //         // dashboardUiConfig: { hideTitle: true }, // dashboard UI config: hideTitle, hideTab, hideChartControls (optional)
    //       });
    //     console.log("THE RES FROM THE EMBED ?")
    //     console.log(res)
    //     console.log(res.getScrollSize())
    //     console.log()
    //     document.getElementById("superset_container").children[0].style = iframe_style
        
        
    // }

    return (
        // <div className="relative flex  w-full h-screen mb-12">

            <div id="superset_container" className="w-full">
                {!enabled &&
                <div>BI is not enabled. Please contact your consultant.</div>
                }

            </div>
        // </div>

    )

}
)

export default SupersetDashboard;