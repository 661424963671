import React from 'react';
import '../styles.css';
import { observer } from 'mobx-react-lite'


const base_cls = "field-wraper bg-transparent focus:outline-none rounded-lg py-2 px-4 block w-full appearance-none leading-normal"
const cls_modifiers = {
    "error": " border-red-600 border-2",
    "outlined": " focus-within:border-primary focus-within:border-2 border border-gray-400",
    "readonly": " border border-gray-300 bg-gray-200",
    "outlined_no_focus": " border border-gray-400",

}

const shrinkLabel = {
    transform:'translateY(-1.5em)',
    display:'inline-block'
}

const fixedLabels = ['boolean', 'one2many', 'html']
const no_focus_fields = ['html']

const reducedPadding = ['selection']


const FieldWrapper = observer(function FieldWrapper(props) {
// function FieldWrapper(props) {
    const {field, value, state_attrs, record} = props    
    const error = record._errors[field.name]

    const cls = () => {


        if (error) {

            return base_cls.concat(cls_modifiers["error"])

        }
        if (state_attrs.readonly) {
            return base_cls.concat(cls_modifiers['readonly'])
        }

        
        if(no_focus_fields.includes(field.type)){
            return base_cls.concat(cls_modifiers['outlined_no_focus'])
        }
        return base_cls.concat(cls_modifiers[props.variant])

    }


    const style = {
        padding: reducedPadding.includes(props.type) ? "0.13rem":"",
        

    }




    return (
        <>

            <div style={{...props.style,...style}} className={cls()} >
                <div style={value || fixedLabels.includes(field.type) || state_attrs.readonly ? shrinkLabel:{}} className="field-label rounded text-xs bg-white font-medium text-primary px-2 absolute">
                    {field.description}
                </div>
                {props.children}
                

            </div>
          
        </>


    )

});

export default FieldWrapper;