import { useMediaQuery } from 'react-responsive'



const sizes = {
    'mobile': {maxWidth:639},
    'sm': {minWidth:640, maxWidth:767},
    'md':{minWidth:768, maxWidth:1023},
    'lg':{ minWidth: 1024, maxWidth:1279 },
    'xl': { minWidth: 1280 }

}

const getLimits = (screen, up, down) =>{

    let limits = {...sizes[screen]}
    if(up){
        delete limits['maxWidth']
    }
    if(down){
        delete limits['minWidth']
    }

    return limits;

    
}

export const IsScreen = (props ) =>{

    const limits = getLimits(props.type, props.up, props.down)
    const res = useMediaQuery(limits)
    return res
}

export const Mobile = ( props ) => {
    const limits = getLimits('mobile', props.up, props.down)
    const isMobile = useMediaQuery(limits)

    return isMobile ? props.children : null
  }

export const Sm = (props) => {
  const limits = getLimits('sm', props.up, props.down)
  const isDesktop = useMediaQuery(limits)
  return isDesktop ? props.children : null
}

export const Md = (props ) => {
  
  const limits = getLimits('md', props.up, props.down)
  
  const isTablet = useMediaQuery(limits)

  return isTablet ? props.children : null
}

export const Lg = (props) => {
  const limits = getLimits('lg', props.up, props.down)
  const Lg = useMediaQuery(limits)
  return Lg ? props.children : null
}

export const Xl = (props) => {
  const limits = getLimits('xl', props.up, props.down)
  const Xl = useMediaQuery(limits)
  return Xl ? props.children : null
}

const Screens = {
    Mobile,
    Sm,
    Md,
    Lg,
    Xl,
    
}

export default Screens