import React from 'react';
import Paper from './Paper.js'


function DefaultContainer(props){

    return(
        <div
            className={props.className}
            style={props.style || {}}
        >
            {props.children}
        </div>
    )
}

const Surfaces = {
    'paper':Paper,
    'base': DefaultContainer
    
}



function Container(props) {

    const Surface = Surfaces[props.type];



    

    return (
        
        <Surface 
            className={props.className}
            style={props.style || {}}
        >
            {props.children}
        </Surface>

    )

}

export default Container;