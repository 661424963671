import React, {  Suspense } from 'react';

function CustomLoader(props) {
   
    const LazyComponent = React.lazy(()=> import(`../../../custom_views/views/${props.component}.js`))
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <LazyComponent {...props}/>
        
        
      </Suspense>
    );
  }

export default CustomLoader