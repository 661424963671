
import { makeAutoObservable, autorun, runInAction, action, observable, makeObservable, computed } from "mobx"
import {evalCondition} from '../common'

export class StateAttrs {
    field = false
    record = false
    readonly = null
    required = null
    invisible = null
    dependencies = []
    inputs = ""
    loading = true
    initialized = false
    states = {}

    get definition(){
        return {'required':this.required,'invisible':this.invisible,'readonly':this.readonly}
    }

    constructor(field, record) {
        makeObservable(this, {
            required: observable,
            invisible:observable,
            readonly:observable,
            
            // toogleLoading: action,
            initialized: observable,
            loading:observable,
            dependencies:observable,
            inputs:observable,
            reload: action,
            update_inputs:action,
        })

        this.field = field
        this.states = this.field.states
        this.record = record
        const default_attrs = field.default_state_attrs
        this.invisible = default_attrs.invisible
        this.readonly = default_attrs.readonly
        this.required = default_attrs.required

        // this.value = browseObject(record._values, field.expression)
        this.loading = false
        this.dependencies = this.get_dependencies()
        // this.update_inputs()


    }

    get_dependencies(){
        let dependencies = []
        if(this.states){
            //remove first and last {}
            let expr = this.field.states.substring(1, this.field.states.length-1);
            
            expr.match(/{([^}]+)}/g).forEach(function(field_name){
                //remove first and last {}
                field_name = field_name.substring(1, field_name.length-1);
                
                //if the value is part of an expression, just take the root field
                if(field_name.includes('.')){
                    field_name = field_name.split('.')[0]
                }
                if(!dependencies.includes(field_name)){
                    dependencies.push(field_name)
                }
                
            })

            
        }
        return dependencies
    }

    create_json_value(){
        let vals = {}
        
        this.dependencies.forEach(function(dep){
            const field =  this.field_by_name(dep)
            if(!field){
                console.log("NO FIELD")
                throw new Error("Check the state_attrs configuration of the field: "+this.field.name)
            }
            const value = field.get_value(this.record)
            vals[dep] = value
            
        }.bind(this))
            


        return JSON.stringify(vals)
    }

    update_inputs(){
       
        this.inputs = this.create_json_value()
    }

    field_by_name(fname){
        return this.record.screen.fields.find(f => f.name === fname)
    }

    

    get_value() {
     if(!this.dependencies.length){
        return this
     }
     else{
         if(this.inputsChanged()){
             return this.reload()
             
         }
         else{
             return this
         }
     }

    }
    inputsChanged(){
        let res = false
        const new_values = this.create_json_value()

        if(new_values !== this.inputs){
            res = true
        }
        

        return res
    }

    get_part_value(part){
        let value
        if(typeof part === 'string' && part.startsWith('{')){
            
            let fname = part.substring(1, part.length-1);
            value = this.field_by_name(fname).get_value(this.record, true)
        }
        else{
            value = part
            
        }
        return value;
        
    }

    reload(){
        // this.readonly = !this.readonly;
        let json = JSON.parse(this.states)
        
        for(let attr in json){

            let conditions = json[attr].map(function(condition){
                let p1 = this.get_part_value(condition[0])
                let operator = condition[1]
                let p2 = this.get_part_value(condition[2])
                
                return evalCondition(p1,operator,p2)


            }.bind(this))
            
            this[attr] = conditions.every(function (a) {
                return a === true
            })
            
        }
       
        
        this.update_inputs()
        
        return this

        
        
    }
    
}