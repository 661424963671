import BinaryImagePreview from './BinaryImagePreview'
import ColorPicker from './ColorPicker'
import EmptyWidget from './EmptyWidget'
import TransferList from './TransferList'

import MultiLink from './MultiLink.js';
import MultiSelection from './MultiSelection.js';

let definitions = {
    'binary':{
        'image':BinaryImagePreview
    },
    'char':{
        'color_picker':ColorPicker
    },
    'multi_selection':{
        'transfer_list':MultiSelection
    },
    'multi_link':{
        'transfer_list':MultiLink
    }
}

const get = (field_type,name) => {
    if(definitions[field_type] && definitions[field_type][name]){
        return definitions[field_type][name]
    }
    else{
        return EmptyWidget
    }
    

}

export const widgets = {
    get
}