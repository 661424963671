import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite'
import { ReactComponent as EditPencil } from '../ui/icons/svg/edit-pencil.svg';
import IconButton from '../ui/buttons/IconButton.js';
import DropDownMenu from '../ui/DropDownMenu.js';

const ViewEditorDropdown = observer(function ViewEditorDropdown(props) {
    const [anchorEl, setAnchorEl] = React.useState(false);
    const [openMenu, setOpenMenu] = React.useState(false);
    const {screen} = props
    const menu_options = screen.editor.edition_options || []

    function onIconClick(e){
        console.log("clicking")
        setAnchorEl(e.currentTarget);
        toogleMenu()
    }
    function toogleMenu(){
        setOpenMenu(!openMenu)
    }

    return (
        <>
        <DropDownMenu
                id="actions-menu"
                anchor={anchorEl}
                options={menu_options}
                open={openMenu}
                onClose={toogleMenu}
            />
        <IconButton onClick={(e) => { onIconClick(e) }} color="transparent">
            <EditPencil style={{ width: '.8em', height: '.8em' }} className="fill-current  text-xl cursor-pointer w-4 h-2" />
        </IconButton>
        </>
    )

});
export default ViewEditorDropdown;