import React from 'react';


function CheckBox(props) {



    return (

        
            <input
                id={props.id || props.name}
                ref={props.inputRef}
                name={props.name}
                label={props.label}
                required={props.required}
                readOnly={props.readOnly}
                className={props.className}
                checked={props.value === true}
                onChange={props.onChange}
                onBlur={props.onBlur}
                type='checkbox'
                onKeyDown={props.onKeyDown}
                style={props.style}
                autoFocus={props.autoFocus}

            />
        



    )

}

export default CheckBox;