import React from "react";
import Field from "./Field.js";
import Select from "react-select";
import IconButton from "../buttons/IconButton";
import { ReactComponent as DeleteIcon } from "../icons/svg/close.svg";
import { observer } from "mobx-react-lite";

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    borderRadius: "0px",
    marginTop: "0px",
    width: "10em",
  }),

  control: (provided, state) => ({
    ...provided,
    border: "0px",
    backgroundColor: "transparent",
    boxShadow: "none",
    height: "100%",
  }),
  menuPortal: (provided, state) => ({
    ...provided,
    // left: provided.left - 80,
    zIndex: 9999,
  }),
  valueContainer: (provided, state) => ({
    // display: 'none'
  }),
};

const initialState = {
  operator_options: [],
};

const Filter = observer(function Filter(props) {
  const { filter, removeFilter, record } = props;

  return (
    <div className="w-auto border-b-2">

      <div className="flex">
        {!filter.required && (
                  <IconButton
                    onClick={() => {
                      removeFilter(filter);
                    }}
                    color="warning"
                  >
                    <DeleteIcon
                      style={{ width: ".8em", height: ".8em" }}
                      className="fill-current text-base cursor-pointer w-4 h-2"
                    />
                  </IconButton>
                )}

      <div   style={{ width: "35%"}}>
        <Select
          isSearchable
          value={filter.field}
          styles={customStyles}
          placeholder={"Campo"}
          isDisabled={filter.required}
          // menuPortalTarget={document.body}
          menuPosition={"fixed"}
          onChange={(value) => {
            filter.setField(value);
          }}
          options={filter.field_options}
          getOptionValue={(option) => option["id"]}
          getOptionLabel={(option) => option["description"]}
        />
      </div>

      <div style={{ width: "25%"}}>
        <Select
          isSearchable
          value={filter.operator}
          styles={customStyles}
          placeholder={"Operador"}
          isDisabled={filter.required}
          // menuPortalTarget={document.body}
          menuPosition={"fixed"}
          onChange={(value) => {
            filter.setOperator(value);
          }}
          options={filter.operator_options}
        />
      </div>

      <div style={{ width: "40%"}}>
        {filter.field && <Field field={filter.field} record={record} />}
      </div>
      </div>
    </div>
  );
});

export default Filter;
