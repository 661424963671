import { makeAutoObservable, computed, action, runInAction, observable, makeObservable } from "mobx"
import { Screen } from './Screen';
import { BoardScreen } from './board/BoardScreen';
import {HTMLScreen} from './html/HtmlScreen';
import {SpreadsheetScreen} from './list/SpreadsheetScreen'
import {GraphScreen} from './graph/GraphScreen'
import { CalendarScreen } from "./calendar/CalendarScreen";



export class ScreenGroup {
    screens = {}
    connection = false
    active_screen = false


    constructor(connection) {
        makeAutoObservable(this, {
            addScreen: action,
            removeScreen: action,
            clear: action
        }
        )
        this.connection = connection
        this.screens = {}
        this.active_screen = false


    }

    set_active_screen(screen) {
        runInAction(() => {

            if (this.active_screen) {
                for (let id in this.active_screen.field_childs.screens) {
                    this.active_screen.field_childs.removeScreen(id)
                }
            }


            this.active_screen = screen
        })
    }



    addScreen(view, history, initial_search, route_state = {}, set_active = true, is_modal = false, parent = false, initialize_fields = true, initialize_actions = true, initialize_data=true, initialize_callback=false, fileHandler=false) {
        
        let res = ""
        const setScreenTitle = (screen)=>{
            let title = route_state[initial_search.action_id] ? route_state[initial_search.action_id].title : screen.view.title
            
            screen.set_title(title)
        }
        // TODO: Improve Multi model reload: currently if the screen is multi_model, the whole screen gets recreated. 
        if (!this.screens[view.id] || view.multi_model_view) {
            let screen
            if (SCREEN_TYPES[view.type]) {
                screen = new SCREEN_TYPES[view.type](this, view, this.connection, history, initial_search, route_state, is_modal, parent, initialize_fields, initialize_actions, initialize_data, initialize_callback, fileHandler)
            }
            else {
                screen = new Screen(this, view, this.connection, history, initial_search, route_state, is_modal, parent, initialize_fields, initialize_actions, initialize_data, initialize_callback, fileHandler)
            }

            this.screens[view.id] = screen
            res = screen
            setScreenTitle(res)

        }
        else {
            res = this.screens[view.id]
            //TODO => Refetching data each time the screen is mounted. Connect to stale or parameters.

            setScreenTitle(res)

            if (res.start_empty) {

                if (initial_search.current_search.length) {
                    res.do_search(initial_search)
                }

            }
            else {
                res.do_search(initial_search)
            }

        }
        if (set_active) {
            this.set_active_screen(res)
        }


        return res



    }
    removeScreen(view_id) {
        delete this.screens[view_id]
    }

    clear() {
        this.screens = {}
    }

    getScreen(id) {
        return this.screens.filter(function (view) { return view.id === id })[0]
    }
    createGroup(connection){
        return new ScreenGroup(connection)
    }
}

const SCREEN_TYPES = {
    'board': BoardScreen,
    'html': HTMLScreen,
    'list': SpreadsheetScreen,
    'spreadsheet': SpreadsheetScreen,
    'graph':GraphScreen,
    'calendar':CalendarScreen
}