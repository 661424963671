import React from 'react';
import Field from '../../ui/fields/Field';
import CheckBox from '../../ui/fields/CheckBox';
import FormButton from '../../ui/buttons/FormButton.js'
import Container from '../../ui/surfaces/Container'
import { observer } from 'mobx-react-lite'
import clsx from 'clsx';

const field_renderers = ['html']

const fieldColspan = {
    1: "md:col-span-1",
    2: "md:col-span-2",
    3: "md:col-span-3",
    4: "md:col-span-4"
}
const FieldValue = observer(function FieldValue(props) {
    const { field, record } = props;

    return (
        <div className={" ".concat(fieldColspan[field.colspan || 4])}>
            {field_renderers.includes(field.type) ?
                // <Field
                //     field={field}
                //     record={record}
                //     variant="outlined"
                //     noWrap

                // />
                <div contentEditable='false' dangerouslySetInnerHTML={{ __html:field.get_string_value(record) }}></div>
                :
                <>{field.get_string_value(record)}</>
            }

        </div>
    )
})

const FieldsGrid = observer(function FieldsGrid(props) {
    const { fields, record } = props;

    return (
        <div className="flex-grow grid grid-cols-4 w-full row-gap-5 col-gap-4 py-4 px-4">
            {fields && record ?
                <>
                    {fields.map((field, index) => (

                        <FieldValue
                            field={field}
                            record={record}
                        />

                    ))}
                </>
                :
                ""
            }
        </div>
    )
})

const ListCardItem = observer(function Cell(props) {

    const { screen, record } = props;
    const primary_fields = screen.mobile_primary_fields
    const secondary_fields = screen.mobile_secondary_fields
    const header_image_field = screen.mobile_record_image_header_field
    const isSelected = screen.is_selected(record.id)

    function getHeaderImage() {

        const field_value = header_image_field.get_value(record)
        const uri = field_value ? field_value.data : false

        if (!uri) {
            return (<></>)
        }

        return (
            <img
                style={{height:'100%'}}
                className='img-fluid'
                src={uri}
            />
        )
    }


    return (

        <Container  type="base" className="border border-gray">
            <div className="flex px-2 py-2 text-gray-600 text-sm" style={{width:'100%'}}>
                {header_image_field ?
                    <>{getHeaderImage()}</>
                    :
                    <>{record.mobile_record_title}</>

                }
            </div>

            {primary_fields.length &&
                <FieldsGrid fields={primary_fields} record={record} />
            }

        </Container>


    )


});

export default ListCardItem;