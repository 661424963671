import React, { useState } from 'react';
import IconButton from '../ui/buttons/IconButton.js';
import { ReactComponent as ReportIcon } from '../ui/icons/svg/report.svg';
import { ReactComponent as LinkIcon } from '../ui/icons/svg/link.svg';
import { ReactComponent as ActionIcon } from '../ui/icons/svg/action.svg';
import { ReactComponent as SearchIcon } from '../ui/icons/svg/search.svg';
import { ReactComponent as ViewIcon } from '../ui/icons/svg/view-show.svg';
import { ReactComponent as Dots } from '../ui/icons/svg/dots-horizontal-triple.svg';
import { ReactComponent as MinusIcon } from '../ui/icons/svg/minus-solid.svg';
import { ReactComponent as ChevronRight } from '../ui/icons/svg/cheveron-right.svg';
import { ReactComponent as ChevronLeft } from '../ui/icons/svg/cheveron-left.svg';
import { ReactComponent as EditPencil } from '../ui/icons/svg/edit-pencil.svg';
import { ReactComponent as AddSolid } from '../ui/icons/svg/add-solid.svg';
import { observer } from 'mobx-react-lite'
import Select from 'react-select';
import DropDownMenu from '../ui/DropDownMenu.js';
import FormButton from '../ui/buttons/FormButton.js';
import ViewEditorDropdown from '../editor/ViewEditorDropdown'
import FilterBar from '../ui/FilterBar.js';
import { FiltersActive } from '../ui/FiltersActive.js';
import clsx from 'clsx';




const customSelectStyles = {
    menu: (provided, state) => ({
        ...provided,
        borderRadius: '0px',
        marginTop: '0px'
    }),

    control: (provided, state) => ({

        ...provided,
        border: '0px',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        // padding: '0px',
        minHeight: '0px',


    }),

    indicatorSeparator: (provided, state) => ({

        ...provided,
        display: 'none',




    }),
    dropdownIndicator: (provided, state) => ({

        ...provided,
        // display: 'none',
        cursor: 'pointer',
        paddingTop: 0,
        paddingBottom: 0,
        maxHeight: '1.5rem',
        // width: '0px',
        color: 'inherit',
        // ':hover': {

        //     display: 'flex',
        // },



    }),
    input: (provided, state) => ({

        ...provided,
        margin: 0,
        paddingTop: 0,
        paddingBottom: 0,
        maxHeight: '1.5rem',



    }),
    valueContainer: (provided, state) => ({

        ...provided,
        cursor: 'pointer',
        paddingTop: 0,
        paddingBottom: 0,
        maxHeight: '1.5rem',




    }),
    singleValue: (provided, state) => ({

        ...provided,
        cursor: 'pointer',
        padding: 0,
        maxHeight: '1.5rem',
        fontSize: 'inherit',
        fontWeight: 'inherit',

        color: 'inherit',
        fontFamily: 'inherit',




    }),
    menu: (provided, state) => ({

        ...provided,
        cursor: 'pointer',
        fontSize: '0.75rem',
        fontWeight: '500',
        color: 'rgba(113, 128, 150,1)',
        fontFamily: 'inherit',

    }),
    option: (provided, state) => ({

        ...provided,
        cursor: 'pointer',


    }),

    menuPortal: (provided, state) => ({
        ...provided,
        zIndex: 9999
    }),



}

const NewRecordButton = observer(function NewRecordButton(props) {
    const { screen } = props
    const readonly = screen.readonly()
    const add_record_button = screen.add_record_button
    if(readonly){
        return (<></>)
    }

    return (
        <>
            {
                add_record_button.text ?
                    <div className="py-1 mr-2 flex items-center" >
                        <FormButton
                            variant="list"
                            state_attrs={{}}
                            className=" truncate text-xs  py-1 px-2"
                            color={add_record_button.color || 'primary'}
                            onClick={(e) => { add_record_button.execute() }}>
                            {add_record_button.text}
                        </FormButton>
                    </div>
                    :
                    <IconButton onClick={(e) => { screen.add_record_button.execute() }} color="transparent">
                        <AddSolid style={{ width: '.8em', height: '.8em' }} className="fill-current  text-xl cursor-pointer w-4 h-2" />
                    </IconButton>

            }
        </>


    )

})

const AddNewRecordButton = observer(function AddNewRecordButton(props) {
    const { screen } = props

    async function createRow(rowIndex, columnIndex) {
        if (!rowIndex || rowIndex === props.rowCount) {
            rowIndex = props.rowCount + 1
            columnIndex = 1
        }

        let record = screen.data.addRecord({values:{}, index:rowIndex})
    }

    return (
        <IconButton color="transparent" onClick={() => createRow()}>
            <AddSolid style={{ width: '.8em', height: '.8em' }} className="fill-current text-xl cursor-pointer w-4 h-2" />
        </IconButton>
    )
    
})

const ActionsBar = observer(function ActionsBar(props) {
    const { screen, parent_ref } = props
    const actions = screen.actions

    // const [buttonActions, setButtonActions] = useState([])
    const [anchorEl, setAnchorEl] = React.useState(false);
    const [openMenu, setOpenMenu] = React.useState(false);
    const [menuOptions, setMenuOptions] = React.useState([]);
    const selected_qty = screen.selected_records ? screen.selected_records.length : 0

    const link_actions = screen.link_actions
    const report_actions = screen.report_actions
    const action_actions = screen.action_actions
    const view_group_actions = screen.view_group_actions
    const general_actions = screen.general_actions
    const delete_action = screen.delete_action
    const button_actions = screen.button_actions
    const editor_actions = screen.editor.edition_options
    const filters = screen.filters



    function executeAction(e, id) {

        // props.executeAction(id, { rowIndex: Object.keys(props.selected) })
        const action = actions.find(act => act.id === id)
        action.execute()


    }

    function onClickButton(e, type) {

        setAnchorEl(e.currentTarget);
        let menu_actions = []
        switch (type) {
            case 'link':
                menu_actions = link_actions
                break;
            case 'general_menu':
                menu_actions = general_actions
                break;
            case 'report':
                menu_actions = report_actions
                break;

            default:
                menu_actions = []
                break;
        }



        setMenuOptions(menu_actions.map(function (act) {
            return { label: act.description, id: act.id, onClick: executeAction }
        }))


        setOpenMenu(true)
    }

    function closeMenu(e) {
        setAnchorEl(false)
        setOpenMenu(false)
    }



    const isVisible = (action) => {

        return action.visible_on_select ? selected_qty > 0 : true

    }





    return (
        <div className="flex">

            {props.isSpreadsheet &&
                <AddNewRecordButton rowCount={props.rowCount} screen={screen} />
            }

            <>
                {screen.filterable ?

                    <IconButton color="transparent" className="text-primary">
                        <FilterBar style={{ width: '.8em', height: '.8em' }} className="fill-current  text-xl cursor-pointer w-4 h-2" filters={filters}
                        parent_ref={parent_ref}
                        />           
                    </IconButton>    
                :
                    ""
                } 
            </>

            {screen.add_record_button !== false &&
                // <IconButton onClick={(e) => { screen.add_record_button.execute() }} color="transparent">
                //     <AddSolid style={{ width: '.8em', height: '.8em' }} className="fill-current  text-xl cursor-pointer w-4 h-2" />
                // </IconButton>
                <NewRecordButton screen={screen} />

            }
            {delete_action !== false &&
                <>
                    {isVisible(delete_action) &&
                        <IconButton onClick={(e) => { executeAction(e, delete_action.id) }} color="warning">
                            <MinusIcon style={{ width: '.8em', height: '.8em' }} className="fill-current  text-xl cursor-pointer w-4 h-2" />
                        </IconButton>
                    }

                </>

            }





            {props.search &&
                <IconButton onClick={props.toogleSearch} color="transparent">
                    <SearchIcon style={{ width: '.8em', height: '.8em' }} className="fill-current  text-xl cursor-pointer w-4 h-2" />
                </IconButton>
            }

            {view_group_actions.length !== 0 &&

                <IconButton onClick={(e) => { onClickButton(e, 'view_group') }} color="transparent">
                    <ViewIcon style={{ width: '.8em', height: '.8em' }} className="fill-current  text-xl cursor-pointer w-4 h-2" />
                </IconButton>

            }

            {link_actions.length !== 0 &&
                <IconButton onClick={(e) => { onClickButton(e, 'link') }} color="transparent">
                    <LinkIcon style={{ width: '.8em', height: '.8em' }} className="fill-current  text-xl cursor-pointer w-4 h-2" />
                </IconButton>
            }

            {report_actions.length !== 0 &&
                <IconButton onClick={(e) => { onClickButton(e, 'report') }} color="transparent">
                    <ReportIcon style={{ width: '.8em', height: '.8em' }} className="fill-current  text-xl cursor-pointer w-4 h-2" />
                </IconButton>
            }

            {action_actions.length !== 0 &&

                <IconButton onClick={(e) => { onClickButton(e, 'action') }} color="transparent">
                    <ActionIcon style={{ width: '.8em', height: '.8em' }} className="fill-current  text-xl cursor-pointer w-4 h-2" />
                </IconButton>

            }
            {general_actions.length !== 0 &&
                <IconButton onClick={(e) => { onClickButton(e, 'general_menu') }} color="transparent">
                    <Dots style={{ width: '.8em', height: '.8em' }} className="fill-current  text-xl cursor-pointer w-4 h-2" />
                </IconButton>

            }
            {props.extra_action_menu ?
                <>{props.extra_action_menu}</>
                :
                ""
            }

            {editor_actions && editor_actions.length !== 0 &&
                <ViewEditorDropdown screen={screen} />


            }
            {button_actions.map((action) => (
                <React.Fragment key={action.id}>
                    {isVisible(action) &&
                        <div className="py-1 mr-2 flex items-center" >
                            <FormButton
                                variant="list"
                                state_attrs={{}}
                                className=" truncate text-xs  py-1 px-2"
                                color={action.button_color || 'primary'}
                                onClick={(e) => { action.execute() }}>
                                {action.description}
                            </FormButton>
                        </div>
                    }

                </React.Fragment>


            ))}




            <DropDownMenu
                id="actions-menu"
                anchor={anchorEl}
                options={menuOptions}
                open={Boolean(openMenu)}
                onClose={closeMenu}
            />





        </div>
    )
});


const SpreadsheetHeader = observer(function SpreadsheetHeader(props) {

    const { screen } = props;
    const { limit, pagination_enabled, page_size_options, page_qty, current_page } = screen.data

    const val = page_size_options.find(o => o.value == limit)

    function onChangeLimit(value) {
        console.log("Changing Limit")
        console.log(value)
        screen.set_limit(parseInt(value.value))
    }


    return (
        <div className="flex h-full w-40">
            {pagination_enabled &&

                <div className="block border-l w-full text-gray-600 border-r border-gray-300 text-xs font-medium">

                    <sup className='px-10'>Página {current_page} de {page_qty}</sup>
                    <div className="hover:text-primary" style={{margin:'-10px 15px 0 16px'}}> {/* margin-top: -10px; */}
                        <Select
                            isSearchable={false}
                            value={val}
                            styles={customSelectStyles}
                            menuPortalTarget={document.body}
                            menuPosition={'fixed'}
                            // placeholder={field.description}
                            onChange={(value) => onChangeLimit(value)}
                            options={page_size_options}
                        />
                    </div>




                </div>







            }

        </div>



    )

});

const RecordCounter = observer(function RecordCounter(props) {

    const { screen, className, style } = props;
    const count = screen.data.count
    const cls = "flex items-center justify-center text-gray-600 border-r border-l border-gray-300 text-xs font-medium ".concat(className)

    return (
        <div className={cls} style={style}>
            <div className='w-20 mr-2 ml-2'>{count} Resultados</div>
        </div>



    )

});

const PaginationControl = observer(function PaginationControl(props) {

    const { screen } = props;
    const { pagination_enabled } = screen.data


    return (
        <div className='flex'>
        {/* <div className="flex items-center justify-center col-span-4 text-gray-600 border-r border-gray-300 text-xs font-medium">
                   <div>{screen.data.count} Resultados</div>
                  
                </div> */}
            <RecordCounter screen={screen} />
            {pagination_enabled &&
                <div className='flex items-center'> 

                    <div>
                        <IconButton onClick={(e) => { screen.data.prevPage() }} color="transparent">
                            <ChevronLeft style={{ width: '.8em', height: '.8em' }} className="fill-current  text-xl cursor-pointer w-4 h-2" />
                        </IconButton>

                    </div>

                    <div >
                        {['list', 'spreadsheet'].includes(screen.type) &&

                            <SpreadsheetHeader screen={screen} />
                        }


                    </div>
                    <div>
                        <IconButton onClick={(e) => { screen.data.nextPage() }} color="transparent">
                            <ChevronRight style={{ width: '.8em', height: '.8em' }} className="fill-current  text-xl cursor-pointer w-4 h-2" />
                        </IconButton>

                    </div>
                </div>
            }

        </div>






    )

});



const ViewHeader = observer(function ViewHeader(props) {

    const [search, setSearch] = useState(false)
    const { screen, hide_toolbar, title_classname, container_classname } = props;
    const { pagination_enabled, show_records_count } = screen.data
    const selected_qty = screen.selected_records ? screen.selected_records.length : 0
    const { title } = screen
    const base_container_cls = "w-full flex justify-between mb-2"
    const container_cls = container_classname ? clsx(container_classname,base_container_cls):base_container_cls
    const isSmartOption = screen.smart_sort_options && screen.smart_sort_options.length > 0 ? true : false;

    
    function toogleSearch() {
        
        setSearch(!search)
    }
    
    const changueSmartOrder = (e) => {
        let fieldName = e.field.rec_name
        let valueName = e.value
        screen.set_order(fieldName, valueName)
    }
    
    const ref = React.createRef();
    
    return (
        <div>
        <div className={container_cls}>
            <div ref={ref} className="w-full flex items-center justify-between px-2 appearance-none leading-normal mb-1 ">

                <div className="col-span-4 flex h-full items-center overflow-hidden whitespace-no-wrap">
                    <div className={title_classname ? title_classname : "text-md text-left text-gray-600 font-medium"}>{title}</div>

                </div>


                {(selected_qty > 0 && !hide_toolbar) &&
                    <div>
                        <div className="text-sm bg-light-selected px-2 py-1 rounded-full text-primary text-left text-gray-600 font-medium">{selected_qty} registros seleccionados</div>
                    </div>

                }

                {/* <div className="col-start-8 col-span-1">
                    {['list', 'spreadsheet'].includes(screen.type) &&

                        <SpreadsheetHeader screen={screen} />
                    }
                </div> */}
                {!hide_toolbar &&
                    <div className='flex'>

                        <ActionsBar
                            parent_ref={ref}
                            toogleSearch={toogleSearch}
                            search={props.filter_bar ? true : false}
                            screen={screen}
                            isSpreadsheet={props.isSpreadsheet}
                            extra_action_menu={props.extra_action_menu}
                            rowCount={props.rowCount}
                            
                        />
                       
                    </div>
                }
                     

                {/* <div className="flex mb-2 flex-row justify-between items-center  rounded-lg px-2  w-full appearance-none leading-normal">
                {search ?
                    <>{props.filter_bar()}</> : ""
                }
            </div> */}

            </div>
                    { 
                        isSmartOption ? 

                        <div className='flex items-center border-l border-gray-300'>
                        <div className="hover:text-primary w-40 text-gray-600  text-xs font-medium" >
                            <Select
                                placeholder={'Ordenar'}
                                options={screen.smart_sort_options}
                                onChange={(value) => changueSmartOrder(value)}
                                getOptionLabel={option => option.name}
                                getOptionValue={option => option.name}
                                styles={customSelectStyles}
                            />
                        </div>
                        </div>
                   :''
                   }


            {pagination_enabled ?
                <PaginationControl screen={screen} />
                :
                <>
                    {show_records_count &&
                        <RecordCounter screen={screen} className="px-4" style={{ border: '0px' }} />
                    }
                </>
            }
             

        </div>
        {screen.filterable ?
            <FiltersActive filters={screen.filters}/>
            : ""
        }
</div>




    )

});
export default ViewHeader;