import { makeAutoObservable, computed, action, runInAction, observable, makeObservable } from "mobx"
import { Screen } from '../Screen'

export class HTMLScreen extends Screen {
    template_source = ""
    template_field = ""
    view_template = ""

    get template_field_object() {
        let field = false
        if (this.template_field && this.fields.length) {
            field = this.fields.find(f => f.name === this.template_field)
        }

        return field
    }
    get content() {
        let content = ""
        if (this.template_source === 'view_field' && this.active_record.id) {
            content = this.template_field_object.get_value(this.active_record)
        }
        else {
            content = this.view_template
        }

        return content

    }
    constructor(group, view, connection, navigate, initial_search, route_state, is_modal, parent, initialize_fields = true, initialize_actions = true, initialize_data=true, initialize_callback=false, fileHandler=false) {

        super(group, view, connection, navigate, initial_search, route_state, is_modal, parent, initialize_fields = true, initialize_actions = true, initialize_data=true, initialize_callback=false, fileHandler=false)
        this.template_source = view.html.template_source
        this.template_field = view.html.template_field
        this.view_template = view.html.view_template

        makeObservable(this, {
            view_template: observable,
            template_field: observable
        })

    }





}