import React from 'react';

function FormDivider(props) {

    

    return (
        <div style={{display:props.invisible ? 'none':''}} className="bg-modal-background text-gray-700 text-sm font-medium rounded px-2 w-full">
            {props.text}
        
        </div>


    )

}

export default FormDivider;