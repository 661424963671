import React, { useContext, useEffect, useState } from 'react';

import AsyncSelect from 'react-select/async';
import {observer } from 'mobx-react-lite'
import { computed } from 'mobx'


//TODO: should support get its own options or receive options.


const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    borderRadius: '0px',
    marginTop: '0px'
  }),
  container: (provided, state) => ({

    ...provided,
    height:'100%',
    // backgroundColor: 'red',
    
    




  }),

  control: (provided, state) => ({

    ...provided,
    border: '0px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    // padding: '0px',
    minHeight: '0px',
    top:'10%'
    
    




  }),
  clearIndicator: (provided, state) => ({

    ...provided,
    cursor: 'pointer',
    paddingTop:0,
    paddingBottom:0,
    maxHeight:'1.5rem',
    ':hover': {

      color: 'red',
    },


  }),
  indicatorSeparator: (provided, state) => ({

    ...provided,
    cursor: 'pointer',
    margin:0,
    


  }),
  dropdownIndicator: (provided, state) => ({

    ...provided,
    cursor: 'pointer',
    paddingTop:0,
    paddingBottom:0,
    maxHeight:'1.5rem',
    


  }),
  input: (provided, state) => ({

    ...provided,
    margin:0,
    paddingTop:0,
    paddingBottom:0,
    maxHeight:'1.5rem',
    top:'40%'
    


  }),
  valueContainer: (provided, state) => ({

    ...provided,
    cursor: 'pointer',
    paddingTop:0,
    paddingBottom:0,
    paddingLeft:0,
   /*  maxHeight:'1.5rem', */
    
    


  }),
  placeholder: (provided, state) => ({

    ...provided,
    cursor: 'pointer',
    paddingTop:0,
    paddingBottom:0,
    maxHeight:'1.5rem',
    top:'40%'
    


  }),
  singleValue: (provided, state) => ({

    ...provided,
    cursor: 'pointer',
    padding:0,
    maxHeight:'1.5rem',
    overflow:'unset',
    top:'40%'
    
    


  }),
  
  menuPortal: (provided, state) => ({
    ...provided,
    zIndex: 9999,
    minWidth:200
  }),



}

export const AsyncSelectField = observer(function AsyncSelectField(props) {




  //new
  const { field, record,onBlur } = props;
  const value = field.get_value(record)
  const [wKey, setWkey] = useState("")
  const key_fields = computed(() => field.get_key_fields_values(record)).get()
  
  // HOOKS
  //TODO: Review
  useEffect(() => {
    // const key_fields = field.get_key_fields_values(record)
    
    let newK = ""
    for(let k in key_fields){
      newK = newK.concat(key_fields[k])
    }
    if(newK!==wKey){
      console.log("I HAVE A NEW KEY")
      console.log(field.name)
      console.log(newK)
      console.log(wKey)
      setWkey(newK)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key_fields]);


  const loadOptions = (inputValue, callback) => {

    if (props.state_attrs.readonly) {
      return callback([])
    }
    field.getOptions(record, inputValue).then(function (options) {

      //Replace when async search implemented
      if (inputValue) {
        options = options.filter(i =>
          i.rec_name.toLowerCase().includes(inputValue.toLowerCase())
        );
      }


      callback(options)


    })


  };


  return (

    <AsyncSelect
      id={props.name}
      name={props.name}
      key={wKey ? wKey : props.name}
      ref={props.inputRef}
      isSearchable
      isClearable
      placeholder={!props.value ? field.description : ""}
      noOptionsMessage={() => { return "" }}
      value={value}
      getOptionLabel={(option) => option.rec_name}
      getOptionValue={(option) => option.id}
      required={props.state_attrs.required}
      isDisabled={props.state_attrs.readonly}
      menuPortalTarget={document.body}
      menuPosition={'fixed'}
      onChange={(value) => { 
        
        field.set_value(value, record) 
        onBlur(false,value)
      }}
      cacheOptions
      defaultOptions
      onBlur={props.saveOnBlur}
      styles={customStyles}
      onKeyDown={props.handleKeyDown}
      loadOptions={loadOptions}
      autoFocus={props.autoFocus}
      isMulti={props.multi}


    />



  )
})

export default AsyncSelectField;