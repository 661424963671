import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react-lite'

const base_cls = "bg-transparent focus:placeholder-transparent placeholder-gray-900  placeholder-opacity-50 focus:outline-none rounded-lg w-full appearance-none leading-normal"


const FloatField = observer(function FloatField(props) {
    const { field, state_attrs, onFocus, record, value } = props;

    const [formatted_value, setFormattedValue] = useState('')

    useEffect(() => {

        let val = field.get_formatted_value(record)
        if (val !== formatted_value) {
            setFormattedValue(val)
        }


    }, []);

    function validateKey(key) {
        return /[0-9]|[-]|[.]|[,]|[e]|[E]/.test(key)
    }

    function handleKeyPress(e) {
        //Change props.type to props.field.type
        if (!validateKey(e.key)) {
            return e.preventDefault()
        }


    }

    function onBlur(e) {

        

        props.onBlur(e, Number(e.target.value))
        setFormattedValue(field.get_formatted_value(record))
    }
    function onChange(e) {
        setFormattedValue(e.target.value)
        
        props.onChange(e, e.target.value)
        

    }

    return (


        <input
            id={props.id || props.name}
            autoFocus={props.autoFocus}
            ref={props.inputRef}
            name={props.name}
            label={props.label}
            required={state_attrs.required}
            readOnly={state_attrs.readonly}
            className={base_cls}
            value={formatted_value}
            // onChange={(e)=>{props.onChange(e,e.target.value)}}
            onBlur={onBlur}
            onChange={onChange}
            // onBlur={props.onBlur}
            //type={field.hide_value ? 'password':''}
            type={field.hide_value ? 'password' : 'float'}
            placeholder={field.description}
            onKeyPress={handleKeyPress}
            onKeyDown={props.handleKeyDown}
            style={props.style}
            autoComplete="off"
            onFocus={onFocus}

        />



    )

});

export default FloatField;