import React, { useEffect, useState, useContext, useReducer } from 'react';
import { observer } from 'mobx-react-lite'
import IconButton from '../ui/buttons/IconButton.js';
import { ReactComponent as CloseIcon } from '../ui/icons/svg/close.svg';
import { ReactComponent as ChevronLeft } from '../ui/icons/svg/cheveron-left.svg';
import { SessionContext, AppContext } from 'kalenis-portal-frontend-main'
import { Modal } from 'kalenis-portal-frontend-main'
import Paper from '../ui/surfaces/Paper.js';
import FormButton from '../ui/buttons/FormButton.js';
import { View_ } from '../views/View.js';
import {

    useHistory

} from "react-router-dom";



function Item(props) {
    const { title, description, onClick, view_definition } = props;

    return (
        <div onClick={() => { onClick(view_definition) }} className="bg-white border border-gray-300 px-2 py-2 cursor-pointer hover:bg-light-selected">
            <div style={{ color: '#24435f' }} className="text-sm text-gray-600 font-bold">{title}</div>
            <div className="text-xs text-gray-600">{description}</div>
        </div>
    )
}

function OptionList(props) {
    const { options, onSelectWizard, close } = props;

    return (
    <div className="px-4 py-4">
        <div className="flex flex-row mb-4 justify-between items-center w-full">
            
            <div style={{ color: '#24435f' }} className="text-xl text-gray-600  font-medium">Añadir</div>
            <IconButton onClick={(e) => { close() }} color="warning">
                <CloseIcon style={{ width: '.8em', height: '.8em' }} className="fill-current  text-xl cursor-pointer w-4 h-2" />
            </IconButton>
        </div>
        
        {options.map((option, index) => (
            <React.Fragment key={index}>
                <Item
                    title={option.name}
                    description={option.description}
                    view_definition={option.view}
                    onClick={onSelectWizard}
                />

            </React.Fragment>



        ))}
        
    </div>)

}

function ViewContainer(props) {
    const { view_definition, dispatch, close } = props;
    const app_context = useContext(AppContext)
    const screen = app_context.addScreen(view_definition, false, {}, {}, true, true, false, true, true, true, false)
    const history = useHistory()
    let actions_map = {}
    actions_map['save'] = (records) => { reloadAndClose(records[0], screen) }
    screen.set_actions_map(actions_map)

    async function reloadAndClose(record, screen){
        //Execute save action
        const view_data = await screen.default_save_action.execute([record],false,false,true)
        //reload routes (the new route will be included)
        await app_context.connection.loadRoutes()
        //Only Save actions are available for custom_path.Those actions returns a {-1:values} as response. 
        let route_id = false
        for(let v in view_data){
            route_id = view_data[v].id
        }
        console.log("ROUTE ID FOUND")
        console.log(route_id)
        const route = app_context.connection.getRouteById(route_id)
        console.log("ROUTE")
        console.log(route)
        if(route){
            history.push(route.path)
        }
        
        close()
        


    }
    function goBack() {
        const value = { view_definition: {} }
        dispatch({ type: 'BACK', payload: { value: value } })
    }

    return (
        <>
            <div className="text-right px-2 flex justify-start">
            <IconButton onClick={(e) => { goBack() }} color="transparent">
                <ChevronLeft style={{ width: '1.5em', height: '1.5em' }} className="fill-current text-xl cursor-pointer w-4 h-2" />
            </IconButton>
            </div>
            {screen &&
                <View_ screen={screen} containerStyle={{ backgroundColor: 'transparent', boxShadow: 'none' }} />
            }

        </>
    )
}



const ViewWizardSelector = observer(function ViewWizardSelector(props) {
    const initialState = {
        'step': 1,
        'view_definition': {}
    }
    const { open, close } = props;
    const connection = useContext(SessionContext)
    const [state, dispatch] = useReducer(reducer, initialState);
    const options = connection.editor_config ? connection.editor_config.view_wizards : []
    const style = state.step === 2 ? { height: 'calc(100% - 101px)' } : {}

    function reducer(state, action) {

        switch (action.type) {
            case 'NEXT':
                const next = state.step + 1
                return { ...state, ...action.payload.value, step: next }
            case 'BACK':
                const back = state.step - 1
                return { ...state, ...action.payload.value, step: back }


            default:
                throw new Error("Invalid Action".concat(action.type))

        }
    }

    function onSelectWizard(view_definition) {
        console.log("View definition getted")
        console.log(view_definition)
        const value = { view_definition }
        dispatch({ type: 'NEXT', payload: { value: value } })

    }




    return (
        <Modal
            open={open}
            className={"sm:w-6/12 z-50 overflow-scroll bg-white  "}
            paper={true}
            onRequestClose={() => { close(false) }}
            style={style}

        >

            {state.step === 1 ?
                <OptionList
                    options={options}
                    onSelectWizard={onSelectWizard}
                    close={close}

                />
                :
                state.step === 2 ?
                    <ViewContainer 
                        view_definition={state.view_definition} 
                        dispatch={dispatch}
                        close={close}
                         />
                    :
                    <></>

            }





        </Modal>
    )

});
export default ViewWizardSelector;