import { makeAutoObservable, computed, action, runInAction, observable, makeObservable } from "mobx"
import { Screen } from '../Screen'
import { BoardWidgets } from './BoardWidget'

export class BoardScreen extends Screen {
    widgets = []
    // child_screens = new ScreenGroup(this.connection)
    child_screens = []
    default_columns = 2

    get view_widgets() {
        return this.widgets.filter(function (widget) { return widget.type === 'view' })
    }
    get action_widgets() {
        return this.widgets.filter(function (widget) { return widget.type === 'action' })
    }
    constructor(group, view, connection, navigate, initial_search, route_state, is_modal, parent, initialize_fields = true, initialize_actions = true, initialize_data=true, initialize_callback=false, fileHandler=false) {
        // view['start_empty'] = true
        super(group, view, connection, navigate, initial_search, route_state, is_modal, parent, initialize_fields = true, initialize_actions = true, initialize_data=true, initialize_callback=false, fileHandler=false)
        this.default_columns = view.default_columns
        this.child_screens = group.createGroup(this.connection)
        makeObservable(this, {
            widgets: observable
        })

    }

    async initialize(search) {
        
        await this.loadFields()
        // this.loadingFields = false;
        // this.data.setInitialLoading(false)
        // this.data.setLoading(false)
        await this.loadWidgets()
        await this.do_search(search)


    }
    createWidgets(data) {
        this.widgets = data.map(function (w) {
            return new BoardWidgets[w.type](w, this)
        }.bind(this))
    }

    async loadWidgets() {
        // if (!this.initialize_fields) {
        //     return false
        // }
        let args = { view_id: this.view.id }
        const abortController = new AbortController();
        let widgets = await this.connection.dispatch('GET', '/view/widgets', args, false, true, false, abortController)
        


        runInAction(() => {
            this.createWidgets(widgets)

        })



    }

    async do_search(search, force) {
        // let count = false
        // let reset_page = false
        
        if (search) {
            // this.search = search
            this.current_search = search.current_search || this.current_search
            this.action_params = search.action_params || this.action_params
            this.action_id = search.action_id || false
            this.order = search.order || this.order
            
        }
        

        
        this.view_widgets.forEach(function(child_view){
            child_view.screen.do_search(search)
        })
        
        
        

    }


}