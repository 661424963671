import React from 'react';
// import {sheet_navigation_signals} from '../../common/common.js'
// import FocusTrap from 'focus-trap-react'
import { ReactComponent as Dots } from '../../ui/icons/svg/navigation-more.svg'

const base_cls = "bg-transparent focus:placeholder-transparent placeholder-gray-900  placeholder-opacity-50 focus:outline-none rounded-lg w-full appearance-none leading-normal"

const widgets = {
    'image': ({ value }) => <img style={{maxHeight:'90%'}} src={value}></img>,
    'base':({value})=><div></div>
    



}

function BinaryField(props) {
    const { field, state_attrs, record } = props;
    const loading = field.is_loading(record)
    const widget = field.attributes.widget || 'base'



    return (
        <>
            {loading ?
                <div className="flex justify-end content-end" style={{ minHeight: '1.5rem' }}>
                    <Dots style={{ width: '1.2em', height: '1.2em' }} className="fill-current text-xl cursor-pointer w-4 h-2 scale-left-to-right" />

                </div>

                :
                <>
                {props.value ?
                    // <div className="text-center">
                        <>{widgets[widget]({value:props.value})}</>
                    // </div>
                    :
                    ""
                }
                </>
            }
        </>




    )

}

export default BinaryField;