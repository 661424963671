import React from 'react';
import { ResponsivePie } from '@nivo/pie'
import { observer } from 'mobx-react-lite'


const PieGraph = observer(function PieGraph(props) {
    const {  graph } = props
    const legend = graph.x_legend
    const data = graph.screen.data_values
    const label_format = graph.x_labels ? value => graph.x_labels[value.id]:value=>value.label
    const tooltip_format = graph.x_labels ? id => graph.x_labels[id]:id=>id

    console.log("Graph x labels")
    console.log(graph.x_labels)
    console.log("DATA i have")
    console.log(data)

    function get_tooltip({datum}){
        
        return (
            <strong className="px-2 py-2 bg-white text-xs"  style={{zIndex:9999,color:datum.color }}>
            {/* {graph.x_labels[id]}: {value} */}
            {/* {format} */}
            {tooltip_format(datum.id)} : {datum.data.value}
          </strong>
        )
    }

    function onSliceClick(slice){
        
        if(graph.screen.default_action){
            const record = graph.screen.data.get_record_by_id(slice.id)
            graph.screen.default_action.execute([record])
        }


    }
    return (


        <ResponsivePie
            // {...props}
            data={data}
            margin={{ top: 20, right: 80, bottom: 30, left: 80 }}
            // height={300}
            // innerRadius={0.5}
            isInteractive
            activeInnerRadiusOffset={6}
            activeOuterRadiusOffset={11}

            padAngle={0.7}
            cornerRadius={3}
            axisBottom={{
                tickSize: 6,
                tickPadding: 5,
                tickRotation: 0,
                legend: legend,
                legendPosition: 'middle',
                legendOffset: 32,
                field: graph.x_field,
                field_labels: graph.x_labels,
                format:label_format


            }}
            colors={{ scheme: 'nivo' }}
            borderWidth={1}
            borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
            arcLinkLabel={label_format}
            tooltip = {(e) => 
             get_tooltip(e)
                
            }
            
            slicesLabelsSkipAngle={10}
            slicesLabelsTextColor="#333333"
            onClick={onSliceClick}
            
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor={{ from: 'color', modifiers: [[ 'darker', 1 ]] }}
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: 'color' }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{ from: 'color', modifiers: [ [ 'darker', 10 ] ] }}

       
        />



    )

});
export default PieGraph;