import React, { useState, useEffect } from 'react';
import Portal from '../common/Portal.js'
import Paper from './surfaces/Paper.js';
import useClickOutside from '../common/useClickOutside.js';




const MenuContent = React.forwardRef((props, ref) => (

    <div ref={ref}>
        <Paper style={props.position}>
            {props.options.map((item, index) => (

                <div className="text-xs text-gray-800 font-medium py-2 px-2 cursor-pointer hover:bg-light-hover hover:text-primary border-t-0.5 border-gray-300" 
                    key={index} 
                    onClick={(e)=>item.onClick(e,item.id)}>
                        {item.label}
                </div>
            ))}
        </Paper>
    </div>



));

function DropDownMenu(props) {
    const [position, setPosition] = useState({})
    const width = 150
    const content_ref = React.createRef();
   
    
    useClickOutside(content_ref, props.onClose, props.open)
    
    


    useEffect(() => {

        if (props.anchor) {
            const rect = props.anchor.getBoundingClientRect()

            console.log("RECT")
            console.log(rect)
            let left = rect.x + rect.width / 2
            let top = rect.y + window.scrollY + rect.height

            if (window.innerWidth - left < width) {
                left = left - width
            }

            let pos = {
                position: 'absolute',
                left: left,
                top: top,
                width: width,
                zIndex: '9999'
            }
            setPosition(pos)
        }




    }, [props.anchor]);





    return (
        <>
            {props.open &&
                <Portal>

                    <MenuContent ref={content_ref} position={position} {...props} />

                </Portal>
            }
        </>


    )

}
export default DropDownMenu;


            //             offsetHeight: 52
            // offsetLeft: 24
            // offsetParent: div.w-full.z-50.text-contrast-primary.shadow-md.top-0.right-0.fixed.bg-primary.
            // offsetTop: 6
            // offsetWidth: 600
            // scrollHeight: 52
            // scrollLeft: 0
            // scrollTop: 0
            // scrollWidth: 600