import React, {  useState, useEffect } from 'react';
import Portal from '../common/Portal.js'
//import useClickOutside from '../common/useClickOutside.js'



const DropDown = React.forwardRef((props, ref) => (

    <>
    {props.open &&
    
    <Portal>
    <DropDownP key={'filter_bar'} parent_ref={ref} {...props}> 
        {props.children}
    </DropDownP>

</Portal>
    }
        
    </>
    
    

));


function DropDownP(props) {
    const [position, setPosition] = useState({})

    const { screentype, posx, posy } = props;

    useEffect(() => {
        const dimensions = props.parent_ref.current.getBoundingClientRect()
        let pos;
        if (screentype === "spreadsheet") {
            pos = {
                position: 'absolute',
                left: posx,
                top: posy,
                width: dimensions.width,
                zIndex:'9999'
            }
        } else if (screentype === "form"){
            pos = {
                position: 'absolute',
                left: dimensions.left,
                top: posy,
                width: dimensions.width,
                zIndex:'9999'
            }
        } else {
            pos = {
                position: 'absolute',
                left: dimensions.left,
                top: dimensions.y >= 300 ? ((dimensions.y + window.scrollY + dimensions.height) - 300) : dimensions.y + window.scrollY + dimensions.height,
                width: dimensions.width,
                zIndex:'9999'
            }
        }
                
        if(props.modifiers){
            pos.width +=props.modifiers.width || '100%'
            pos.left+= props.modifiers.left || 0
            pos.top += props.modifiers.top || 0
        }
        
        
        setPosition(pos)
        


    }, [props.parent_ref]);


    // const wrapperRef = React.useRef(null);

    
   

    return(
        
            <div {...props} style={position}>
                {props.children}
            </div>
            

    )

}

export default DropDown;