import React from 'react';
import DropDown from '../DropDown'
import Calendar from 'react-calendar';


function CalendarPicker(props) {

    
    
    return (

        <DropDown
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    e.preventDefault()
                    e.stopPropagation()
                    // submitSearch()
                }
            }}
            ref={props.calendar_ref}
            open={props.open}
            onClose={props.close}
            modifiers={props.modifiers}
        >
            <Calendar
                onChange={props.onChange}
                value={props.value}
                // minDate={props.minDate}
                {...props.extra_props}

                
            />

        </DropDown>
    )

}

export default CalendarPicker;