import { action, makeObservable, observable } from "mobx"

export class ScreenEditor {
    permissions = {}
    screen = {}
    permissions = {}

    get fields_permission(){
        return this.permissions.fields
    }
    get edition_options(){
        const options = []
        if(this.fields_permission){
            options.push({'id':'add_field','label':'+ Add Field', 'onClick':()=>{this.openFieldScreen()}})
        }
        return options
    }
    
    constructor(config, screen, permissions={}) {
        this.config = config;
        this.screen = screen;
        this.permissions = permissions || {};
        makeObservable(this,{
            permissions:observable,
            config:observable
            
        })   
       

    }

    async executeAndReload(record,screen){
        await screen.default_save_action.execute([record],false,false,true)
        
        await this.screen.loadFields()
        
    }

    new_screen_callback(screen){
        
        let new_values = {'view':this.screen.id, 'view.':{'id':this.screen.id,'rec_name':this.screen.title}}
        screen.active_record.set_values(new_values)
        
        
    }

    openFieldScreen(){
        const view_definition = this.config.get_definition('fields')
        
        

        const field_screen = this.screen.modal_childs.addScreen(view_definition, false, {}, {}, true, true, this.screen, true,true,true, (screen)=> this.new_screen_callback(screen))
        let actions_map = {}
        // let default_save_action = field_screen.default_save_action
        
        actions_map['save'] = (records) => { this.executeAndReload(records[0], field_screen) }
        field_screen.set_actions_map(actions_map)
        
        
    }
    



}
   

    