import React, { useReducer, useEffect } from 'react';
import { sheet_navigation_signals } from '../../common/common.js';
import moment from 'moment';
import CalendarPicker from './CalendarPicker'
import 'react-calendar/dist/Calendar.css';
import IconButton from '../buttons/IconButton'
import { ReactComponent as CalendarIcon } from '../icons/svg/calendar.svg';
import {common} from 'kalenis-portal-frontend-main'

const base_cls = "bg-transparent focus:placeholder-transparent placeholder-gray-900  placeholder-opacity-50 focus:outline-none rounded-lg w-full appearance-none"



const initialState = {
    'calendar_open': false,
    'calendar_value': "",
    'input_value':"",
    'value': "",
    'changed':false
}



function DateField(props) {
    const calendar_ref = React.createRef();
    const [state, dispatch] = useReducer(reducer, initialState);
    const {state_attrs, field, record} = props
    const minDate = field.get_min_value(record)
    const extra_props = {}
    if(minDate){
        extra_props['minDate'] = minDate
    }
    

    function reducer(state, action) {

        switch (action.type) {
            case 'SET_VALUE':
                
                const calendar_value = action.payload.value._isValid ? moment(action.payload.value).toDate() : moment().toDate()
                // const value = action.payload.value ? getDateValue(action.payload.value) : ""
                const value = action.payload.value._isValid ? action.payload.value : ""
                const input_value = action.payload.value._isValid ? common.formatDate(action.payload.value, props.field.type):""
                
                
                return { ...state, calendar_value: calendar_value, value: value, input_value:input_value, changed:false }
            case 'SET_INPUT_VALUE':

                return {...state, input_value:action.payload.value, changed:true}
            case 'SET_CALENDAR_OPEN':
                const calopen = !state.calendar_open
                const changed = action.payload.changed ? action.payload.changed:false
                return { ...state, calendar_open: calopen, changed:changed }
            default:
                throw new Error("Invalid Action".concat(action.type))

        }
    }

    useEffect(() => {

        dispatch({ type: 'SET_VALUE', payload: {value:props.value} })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value]);

    //format value for the input
    function formatValue(value) {
        if(!value){
            return null
        }
        switch (field.type) {
            case 'date': return moment.utc(value).format('YYYY-MM-DD')
            // case 'datetime': return moment(value).utcOffset(0, true).format('YYYY-MM-DD hh:mm:ss')
            case 'datetime': return moment.utc(value).format('YYYY-MM-DD HH:mm:ss')
            default:throw new Error("Invalid Type".concat(props.type))

        }

    }


    function setValue(value) {
        
        props.onChange("", formatValue(value))
        dispatch({ type: 'SET_CALENDAR_OPEN', payload: {changed:true} })
    }
    function onChangeInput(e){
        dispatch({ type: 'SET_INPUT_VALUE', payload: {value:e.target.value} })
    }
    

    function toogleCalendar() {
        // setCalendarOpen(!calendar_open)
        dispatch({ type: 'SET_CALENDAR_OPEN', payload: {} })
    }


    //temporary function: format value from _es, replace when 18n
    function getEsValue(value){
        let date = moment(value, 'DD-MM-YYYY HH:mm:ss')
        if(!date._isValid){
            return null
        }
        return date
    }
    function onBlurInput(e){
        if(state.changed){
            props.onChange("",formatValue(getEsValue(e.target.value)))
        }
        
    }
    function handleKeyDown(e){
        if(state.changed){
            if(sheet_navigation_signals.includes(e.key)){
                setValue(getEsValue(e.target.value))
            }
        }
        
        
    }


    return (
        <div className="flex"  >
            <input
                id={field.id || field.name}
                autoFocus={props.autoFocus}
                name={field.name}
                label={field.description}
                required={state_attrs.required}
                readOnly={state_attrs.readonly}
                className={base_cls}
                value={state.input_value}
                onChange={onChangeInput}
                onBlur={onBlurInput}
                placeholder={field.description}
                style={props.style}
                onKeyDown={handleKeyDown}

            />
            {!state_attrs.readonly &&
            <div style={{maxHeight:'1rem' }} ref={calendar_ref}>
                
                <IconButton style={{padding:0}} onClick={(e) => { toogleCalendar() }} color="transparent">
                <CalendarIcon style={{ width: '.8em', height: '1rem' }} className="fill-current  text-xl cursor-pointer w-4 h-2" />
            </IconButton>
               
                
            </div>
             }
            <CalendarPicker
                open={state.calendar_open}
                value={state.calendar_value}
                onClose={() => { return true }}
                calendar_ref={calendar_ref}
                onChange={setValue}
                // minDate={minDate ? minDate:false}
                modifiers={{
                    left: -200,
                    width: 200
                }}
                extra_props={extra_props}


            />
        </div>




    )

}

export default DateField;