import React from 'react';
import CheckBox from './CheckBox'
const base_cls = "text-center"

function BooleanField(props) {



    return (

        <div className="w-full text-center">
            <CheckBox
                id={props.id || props.name}
                inputRef={props.inputRef}
                name={props.name}
                label={props.label}
                required={props.required}
                readOnly={props.readOnly}
                className={base_cls}
                value={props.value}
                onChange={(e)=>{props.onChange(e, e.target.checked)}}
                onBlur={props.onBlur}
                type='checkbox'
                onKeyDown={props.handleKeyDown}
                style={props.style}
                autoFocus={props.autoFocus}

            />
        </div>



    )

}

export default BooleanField;