import { makeAutoObservable, autorun, runInAction, makeObservable, action, observable } from "mobx"
import default_logo from './img/isologo-kalenis.png'
import { environment } from '../common'
import { lighten, hexAToRGBA } from './color'
const default_theme_values = {
    logo: default_logo,
    palette: {
        primary: "#195fc7",
        secondary: "rgb(220,0,78)"

    },
}

export class Theme {

    connection = false
    values = default_theme_values
    use_default_theme = false
    logo = ""
    ready = false
    default = true
    tooltip_templates = []

    // get logo() {
    //     return this.values['logo']
    // }
    constructor(connection, use_default_theme=false) {
        makeObservable(this, {
            setTheme: action,
            ready: observable,
            values:observable,
            logo:observable,
            default:observable,
            setLogo:action,
            setDefault:action,
            setTooltipTemplates:action,
           
            
        })
        this.connection = connection;
        
        if(this.connection.api_path){
            this.getTheme()
            
        }
        else{
            this.setTheme(default_theme_values)
            this.setDefault(true)
        }
        

    }



    async getTheme() {
        
        // let args = { host: window.location.host }
        let args = {}
        if (environment() === 'web'){
            args['host'] = window.location.host
        }
        else{
            args['host'] = ''
        }
        let values = default_theme_values
        const abortController = new AbortController();
        let theme = await this.connection.dispatch('GET', '/host_theme', args, false, true, false, abortController)
        let logo = await this.connection.dispatch('GET', '/theme_logo', { theme_id: theme.id }, false, false, true, abortController)
        let tooltip_templates = await this.connection.dispatch('GET','/theme/tooltip_templates',{ theme_id: theme.id }, false, true, false, abortController)
        
        if (theme) {
            values = theme;
            
            if (environment() === 'web') {
                // values["logo"] = URL.createObjectURL(logo)
                this.setLogo( URL.createObjectURL(logo))
            }
            else {
                var reader = new FileReader();
                reader.readAsDataURL(logo.data);
                reader.onloadend = function () {
                    var base64data = reader.result;
                    // values['logo'] = base64data
                    this.setLogo(base64data)
                }.bind(this)

            }
            this.setTooltipTemplates(tooltip_templates)


        }
        this.setDefault(false)
        return this.setTheme(values)


    }
    setLogo(value){
        this.logo = value
    }
    setTooltipTemplates(templates){
        this.tooltip_templates = templates
    }
    getTooltipTemplate(id){
        return this.tooltip_templates.find(t => t.id == id)
    }
    setDefault(value){
        this.default = value;
    }


    setTheme(values) {
        const env = environment()


        this.values = values;


        if (env === 'web') {
            this.setWebTheme(values)
        }
        this.ready = true
        return this

    }
    setWebTheme(theme) {
        document.documentElement.style.setProperty('--color-primary', theme.primary);
        document.documentElement.style.setProperty('--color-contrast-primary', 'rgb(255,255,255)');
        document.documentElement.style.setProperty('--color-lighten-primary', lighten(theme.primary, 12));
        document.documentElement.style.setProperty('--color-secondary', theme.secondary);
        document.documentElement.style.setProperty('--color-disabled', 'lightgrey');
        document.documentElement.style.setProperty('--color-light-selected', hexAToRGBA(lighten(theme.primary, 40), '.3'));
        document.documentElement.style.setProperty('font-size', '1rem');

    }

    




}
