import React, {useEffect } from 'react';
import {View_} from '../../views/View.js'
import { observer } from 'mobx-react-lite'
import { computed } from 'mobx'



const O2MField = observer(function O2MField(props) {

    const {field, record, state_attrs} = props;
    const height = field.height ? (parseInt(field.height)*70)/100: (300*70)/100
    const key_fields = computed(() => field.get_key_fields_values(record)).get()

    useEffect(() => {

        
        field.initialize(record)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [record.id]);

    
  //TODO: Review
  useEffect(() => {
    // const key_fields = field.get_key_fields_values(record)
    
    // if(field.current_record && field.current_record.id != record.id){
    //     console.log("RECORD_ID:Initializing")
    //     console.log(record.id)
    //     field.initialize(record)
    // }
    // else if(!field.initialized){
    //     field.initialize(record)
    // }
    // else{
    //     console.log("o2m key fields changed")
    //     console.log(key_fields)
    //     console.log("RECORD_ID:Reloading")
    //     console.log(record.id)
    //     field.reload()
    // }
    if(key_fields){
        field.reload()
    }
    
    

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key_fields]);

    return (

        // <div className="overflow-hidden" style={{ overflow: 'hidden', height: '100%', paddingTop: '0.5rem' }}>
        <div className="overflow-hidden" style={{ overflow: 'hidden', height: '100%', paddingTop: '0.5rem' }}>
            {/* {(field.view_form && !state_attrs.readonly)&&
                <div onClick={()=>field.openFormView()}>NUEVO</div>
            } */}
            
            
            {field.screen && 
                <View_ screen={field.screen} height={height}/>
                // <View_ screen={field.screen} height={700}/>
            }
            
           

        </div>



    )

});

export default O2MField;