import React from 'react';
// import {sheet_navigation_signals} from '../../common/common.js'
// import FocusTrap from 'focus-trap-react'
const base_cls = "bg-transparent focus:placeholder-transparent placeholder-gray-900  placeholder-opacity-50 focus:outline-none rounded-lg w-full appearance-none leading-normal"


const types = {
    'char': { type: '', validateKey: false, caretPosition: true },
    'float': { type: '', align: 'right', validateKey: (key) => { return /[0-9]|[-]|[.]|[,]|[e]|[E]/.test(key) }, caretPosition: true, formatValue: (value) => { return parseFloat(value) } },
    'integer': { type: 'number', align: 'right', validateKey: (key) => { return /^\d+$/.test(key) }, caretPosition: false },
}

function BaseField(props) {
    const {field, state_attrs, onFocus} = props;

    const inputType = types[props.field.type].type;
    function handleKeyPress(e) {
        //Change props.type to props.field.type
        if(types[props.field.type]){
            if (types[props.field.type].validateKey) {

                if (!types[props.field.type].validateKey(e.key)) {
    
                    return e.preventDefault()
    
                }
    
            }

        }
        
    }

    

   

    return (
        
            
            <input
                id={props.id || props.name}
                autoFocus={props.autoFocus}
                // tabIndex="-1"
                ref={props.inputRef}
                name={props.name}
                label={props.label}
                required={state_attrs.required}
                readOnly={state_attrs.readonly}
                className={base_cls}
                value={props.value}
                onChange={(e)=>{props.onChange(e,e.target.value)}}
                onBlur={props.onBlur}
                //type={field.hide_value ? 'password':''}
                type={field.hide_value ? 'password':inputType}
                placeholder={field.description}
                onKeyPress={handleKeyPress}
                onKeyDown={props.handleKeyDown}
                style={props.style}
                autoComplete="off"
                onFocus={onFocus}

            />
            
        


    )

}

export default BaseField;