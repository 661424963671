import React, { useState, useEffect, useContext } from 'react';
import Paper from '../../ui/surfaces/Paper.js'
import Field from '../../ui/fields/Field.js'
import FormDivider from '../../ui/fields/FormDivider.js'
import FormButton from '../../ui/buttons/FormButton.js'
import LoadingIndicator from '../../common/LoadingIndicator'
import { observer } from 'mobx-react-lite'
import { colord } from 'colord';
import ViewHeader from '../ViewHeader'
const baseFieldCont = "w-full col-span-4 "


const fieldColspan = {
    1: "md:col-span-1",
    2: "md:col-span-2",
    3: "md:col-span-3",
    4: "md:col-span-4"
}

const FormField = observer(function FormField(props) {
    const { field, record } = props;
    const state_attrs = field.get_state_attrs(record)


    return (
        <div style={{ display: state_attrs.invisible ? 'none' : '' }} key={field.name} className={baseFieldCont.concat(fieldColspan[field.colspan] || 4)}>
                <Field
                    field={field}
                    record={record}
                    variant="outlined"
                    show_tooltip
                />
               
        </div>
    )
});
// function FormView(props) {
const FormView = observer(function FormView(props) {


    const container_ref = React.createRef()
    const { screen } = props;
    const fields = screen.visible_fields;
    const record = screen.active_record
    const loading = screen.data.loading




    return (
        <Paper className="py-2 px-2 rounded" style={props.containerStyle}>

            {/* <div className="text-xl px-6 py-1 text-gray-800">{screen.title}</div> */}
            <ViewHeader
                 screen={screen}
                 title_classname="text-xl px-6 py-1 text-gray-800"
                 hide_toolbar={props.hide_toolbar}
                 isSpreadsheet={false}
            />
            <div ref={container_ref} className="flex-grow grid grid-cols-4 w-full row-gap-5 col-gap-4 py-4 px-4">
                {fields && record ?
                    <>
                        {fields.map((field, index) => (

                            <FormField
                                key={field.id}
                                field={field}
                                record={record} />

                        ))}
                    </>
                    :
                    ""
                }



            </div>
            <LoadingIndicator
                ref={container_ref}
                show={loading}
                
            />
        </Paper>


    )

});

export default FormView;