import React, {useContext} from 'react';

import SessionContext from '../../connection/SessionContext'
import {ScreenGroup} from '../../screen/ScreenGroup'
import AppContext from './AppContext'

export function AppContextProvider({ children }) {

  const connection = useContext(SessionContext)
  

  // return AppContext.Provider({value:new ScreenGroup(connection), children:children})
  
    return (
      <AppContext.Provider value={new ScreenGroup(connection)}>
        {children}
      </AppContext.Provider>
    )
  
  
  
  
  }
  
  
  // export AppContextProvider;
