import { makeAutoObservable, autorun, runInAction, computed, action, observable } from "mobx"

import { operators, typeOperators, typeMapping, getDefaultOperator, getOperator, BOOLEAN_OPTIONS } from '../search.js'
import { Record } from "./Record";
import { createField } from "./Field"
//Native Web Compatibility
// import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';

export class Filters {
    type = false
    active_filters = []
    record = ""

    get fields() {
        return this.screen.filterable_fields
    }
    get required_filter_fields(){
        return this.active_filters.filter(function(f){return f.required===true}).map(function(filter){return filter.field})
    }

    constructor(attributes, screen) {
        
        this.type = false
        this.screen = screen
        this.active_filters = []
        this.record = new Record({ id: -1 }, screen)
        
        makeAutoObservable(this, {
            fields: computed,
            addFilter: action,
            removeFilter: action,
            setInitialFilters: action,
            clean: action,
            record:observable,
            // setDefaultFilters:action
        })
        

    }

    getDefaultFilters(){
        const filters = []
        this.screen.default_filters.forEach(function(filter){
            
            let attributes = {}
            attributes['field'] =  this.fields.find(f => f.id === filter.field)
            attributes['operator'] = getOperator(filter.operator)
            attributes['required'] = filter.required
            const new_filter = new Filter(attributes, this)
            filters.push(new_filter)
            
        }.bind(this))
        // this.active_filters = filters;
        return filters
    }

    setInitialFilters() {
        const filters = this.getDefaultFilters()
        this.clean()
        // const default_applied = []
        this.screen.current_search.forEach(function (f) {
            const attributes = {}
            // TODO: Implement default filters again


            // find if field already in default filters
            // const default_filter = filters.find(filter => filter.field_name === f[0])

            //check if the value has already been applied to a default filter. If so,
            //the user added a new filter for the same field.
            // if(default_filter && !default_applied.includes(f[0])){
            //     console.log("Entro por el default bla")
            //     default_filter.field.set_value(f[2], this.record)
            //     default_applied.push(f[0])

            // }
            // else{
                attributes.field = this.fields.find(field => field.name === f[0])
                attributes.operator = getOperator(f[1])
                const filter = new Filter(attributes, this)
                filter.field.set_value(f[2], this.record)
                filters.push(filter)
                
            // }
            

        }.bind(this))
        this.active_filters = filters;
    }

   

    search(navigate=true) {
        if(this.required_filter_fields.length){
            if(!this.record.validate(this.required_filter_fields)){
                return false
            }
        }
        
        const current_search = []
        const search = this.screen.search_state
        this.active_filters.forEach(function (filter) {
            const val = this.record._values[filter.field.name]
            if(val){
                current_search.push([filter.field_name, filter.operator.value, this.record._values[filter.field.name]])
            }
            

        }.bind(this))
        search['search'] = current_search

        if(navigate && this.screen.navigate){
            let url = this.screen.connection.createUrl(search)
            this.screen.navigate(url)
        }
        else{
            search['current_search'] = current_search
            this.screen.do_search(search)
        }
        return true
        

    }
    addFilter() {
        const filter = {}
        filter['field_options'] = this.fields.map(function (f) { return f })
        filter['field'] = ''
        filter['operator'] = ''
        filter['value'] = ''


        this.active_filters.push(new Filter(filter, this))
    }
    removeFilter(filter) {

        const removed = this.active_filters.splice(this.active_filters.findIndex(rec => rec.id === filter.id), 1);
        removed.forEach(function (f) {
            delete this.record._values[f.field.name]
            delete this.record._changed[f.field.name]
        }.bind(this))

    }
    clean() {
        this.active_filters = []
        this.record = new Record({}, this.screen)
    }

     /**
* Get filter id from original field name. If duplicated fnames, the last one will be returned.
* @param {string} fname - Original field name to be searched
* @return {Filter} Filter instance
*/
    get_filter_fname(fname){
        return this.active_filters.find(f=>f.field_name === fname)
        
    }


}

export class Filter {

    field_options = ""
    field = ""
    field_name = ""
    operator = ""
    value = ""
    id = ""
    filter_group = ""
    required = false

    get operator_options() {
        if (!this.field) {
            return []
        }
        return operators.filter(function (op) {
            return typeOperators[typeMapping[this.field.type]].includes(op.value)
        }.bind(this))
    }
    constructor(attributes, filter_group) {
        makeAutoObservable(this, {
            setField: action,
            setOperator: action
        })
        this.field_options = attributes.field_options
        // this.field = attributes.field
        // this.field_name = attributes.field ? attributes.field.name:""

        this.operator = attributes.operator
        this.required = attributes.required || false
        this.value = attributes.value
        
        this.id = uuidv4()
        this.filter_group = filter_group
        if (attributes.field) {
            this.createFilterField(attributes.field)
        }

    }

    setOperator(value) {
        this.operator = value
    }

    getFieldType(field) {
        let type = field.type


        if (field.char_search) {
            type = 'char'
        }
        else {
            switch (type) {
                case 'selection': return 'multi_selection'
                case 'many2one': return 'multi_link'
                case 'boolean': return 'selection'
                default: return type
            }
        }


        return type

    }

    createFilterField(field) {
        const type = this.getFieldType(field)
        const new_attributes = { ...field.attributes }
        new_attributes.type = type
        new_attributes.name = this.id
        new_attributes.readonly = false
        new_attributes.required = this.required
        new_attributes.filter = this;
        this.field_name = field.name
        this.field = createField(new_attributes, this.filter_group.screen)
        

    }

    setField(field) {

        if(this.field){
            this.field.set_value("",this.filter_group.record)
        }
        
        this.createFilterField(field)
        
        this.setOperator(getOperator(getDefaultOperator(field.type)))
        


    }
}
