import { makeAutoObservable, computed, action, runInAction, observable, makeObservable } from "mobx"
import { Screen } from '../Screen'

export class SpreadsheetScreen extends Screen {
    selected_records = []
    list_type = ""
    smart_sort_options = []
    
    get all_records_selected(){
        return this.data.records.length && this.data.records.length === this.selected_records.length
    }

    constructor(group, view, connection, navigate, initial_search, route_state, is_modal, parent, initialize_fields = true, initialize_actions = true, initialize_data=true, initialize_callback=false, fileHandler=false) {
        
        super(group, view, connection, navigate, initial_search, route_state, is_modal, parent, initialize_fields, initialize_actions, initialize_data, initialize_callback, fileHandler)

        this.list_type = view.list_type
        this.smart_sort_options = view.smart_sort_options ? view.smart_sort_options:[]
        makeObservable(this, {
            set_selected_record: action,
            add_selected_records: action,
            remove_selected_records: action,
            selected_records: observable,
            list_type:observable

        })

    }
    record_index(record){
        return this.data.records.indexOf(record)
    }
    is_selected(id) {

        return this.selected_records.findIndex(rec => rec.id === id) >= 0

    }
    
    set_selected_record(records) {
        this.selected_records = records;
    }
    add_selected_records(records) {
        runInAction(() => {
            records.forEach(function (i) {
                this.selected_records.push(i)
            }.bind(this))

        })

    }
    remove_selected_records(records) {
        records.forEach(function (i) {
            let rec = this.selected_records.indexOf(i)
            this.selected_records.splice(rec, 1);
        }.bind(this))
    }

    

    changeSelection({ id = 0, index = 0, toogle = false, range = false }) {
        const record = id ? this.get_record_by_id(id) : this.get_record_by_index(index)
        const selected = this.is_selected(record.id)

        const get_range = (to)=>{
            let res = [...this.selected_records]
            let from = this.selected_records.length ? this.record_index(this.selected_records[this.selected_records.length-1]):to
            
            
            let start = to<from ? to:from
            let end = to<from ? from:to
            
            while(start <= end){
                const record = this.get_record_by_index(start)
                if(!this.is_selected(record.id)){
                    res.push(record)              
                }
                 
                start+=1
            }
            return res
            
        }

        if (toogle) {
            if(!selected){
                this.set_selected_record([record])
            }
            else{
                if(this.selected_records.length>1){
                    this.set_selected_record([record])
                }
                else{
                    this.set_selected_record([])
                }
                
            }
            
        }
        else {
            if(range){
                
                this.set_selected_record(get_range(index))

            }
            else if (!selected) {
                this.add_selected_records([record])

            }
            else {
                this.remove_selected_records([record])
            }
        }

    }

    toogleSelectAll(all_selected){
        let selected = []
        if(all_selected){
            selected = [...selected,...this.data.records]
            
        }
        this.set_selected_record(selected)
    }

    do_search(search,force){

        super.do_search(search, force)
        this.set_selected_record([])
        this.set_active_record(false)

    }


}