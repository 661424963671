import React from 'react';
import Paper from '../../ui/surfaces/Paper.js'
import { observer } from 'mobx-react-lite'


const HtmlView = observer(function HtmlView(props) {

    const { screen } = props;
    //const content = screen.content;
    const content = screen ? screen.content && screen.content : props.htmlValue;


    return (
        <Paper shadowClass="shadow-none" className={props.fromCell ? "" : "py-2 px-2 rounded"} style={props.containerStyle}>
            <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </Paper>
        

    )

});

export default HtmlView;