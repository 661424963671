import React, { useState, useEffect, useReducer } from 'react';
import Paper from '../ui/surfaces/Paper.js'
import BaseField from '../ui/fields/BaseField.js';
import { ReactComponent as SearchIcon } from '../ui/icons/svg/filter.svg';
import { ReactComponent as ChevronDown } from '../ui/icons/svg/chevron-down.svg';
import Filter from '../ui/fields/Filter.js';
import FormButton from '../ui/buttons/FormButton.js';
import DropDown from './DropDown.js';
// import SessionContext from '../auth/SessionContext.js'
import { createUrl } from '../common/common.js'
import { BOOLEAN_OPTIONS, getOperator, getSearchString } from '../common/search'

import {SessionContext} from 'kalenis-portal-frontend-main'
import {observer} from 'mobx-react-lite'
import {


    useHistory,


} from "react-router-dom";


const initialState = {
    'url_filters_ready':false,
    'active_filters': [],
    'field_names': [],
    'raw_values': { 'values': {}, 'changed': {} },
    'filter_string':""

}




const filterStyle = {
    'header': "flex items-center rounded-md flex-grow bg-primary-lighten text-contrast-primary focus-within:text-black focus-within:bg-white",
    'flat': "flex items-center rounded-md flex-grow bg-gray-200 border focus-within:bg-white"
}


const FilterBar = observer(function FilterBar(props){
    const { filters, parent_ref, filtersInCalendar } = props
    const record = filters.record
    const [filters_open, setFiltersOpen] = useState(false)
    const changueColorIcon = filtersInCalendar ? 'fill-current text-red-600 text-2xl cursor-pointer rounded' : 'fill-current text-primary text-2xl cursor-pointer rounded' 
    

    function toogleFilters(){
        setFiltersOpen(!filters_open)
    }

    function addFilter(){
        return filters.addFilter()
    }
    function removeFilter(filter){
        console.log("Removing Filter")
        console.log(filter)
        return filters.removeFilter(filter)
        // return filters.removeFilter()
    }
    function search(){
        
        let search_started = filters.search()
        if(search_started){
            toogleFilters()
        }
        
        
        
        
    }

   //  const ref = React.createRef(); traerlo desde ViewHeader
   
    return (
        
        <div
            // ref={ref}
            //style={{ maxWidth: '600px', ...props.style }}
            //className={props.variant ? filterStyle[props.variant] : filterStyle['header']}
        >
            <SearchIcon 
                    color="primary" 
                     style={{ width: '0.8em', height: '0.8em' }} 
                     className={changueColorIcon}
                     onClick={toogleFilters} />

            {/* <BaseField
                placeholder="Buscar"
                // onChange={filterChange}
                // value={state.filter_string}
                readOnly={true}
                style={{ paddingTop: '.5rem', paddingBottom: '.5rem' }}

            /> */}


         {/*   <ChevronDown onClick={toogleFilters}  style={{ width: '1em', height: '1em' }} className="fill-current text-2xl cursor-pointer w-4 h-4 mr-1 md:mr-4 ml-2" />
 */}
            {filters_open &&

            <DropDown
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault()
                        e.stopPropagation()
                        search()
                    }
                }}
                ref={parent_ref}
                open={filters_open}
                onClose={toogleFilters}
            >
                <Paper className="mt-1 border-t-2 pt-4 rounded" >
                    <div >
                        {filters.active_filters.map((filter, index) => (

                            <div key={index} className="px-8">
                                <Filter
                                    index={index}
                                    filter={filter}
                                    removeFilter={removeFilter}
                                    record={record}
                                    
                                    


                                />
                            </div>




                        ))}

                    </div>
                        <div className="flex items-center justify-between pb-2 pt-3 px-10 w-full">
                            <div>
                            <span className="py-2 px-2 text-sm font-medium cursor-pointer text-primary rounded hover:bg-light-selected" onClick={(e)=>{addFilter()}}>
                                + Añadir Filtro
                            </span>
                            </div>
                            <div>
                                <FormButton
                                 color="primary" 
                                 state_attrs={{invisible:false,readonly:false}}
                                 onClick={(e)=>{search()}}>
                                    BUSCAR
                                </FormButton>
                            </div>
                        </div>
                    
                </Paper>
            </DropDown> 
            }
            




        </div>
        
        

        
    )

})

export default FilterBar;