import React, { useState } from 'react';
import { observer } from 'mobx-react-lite'
import { ReactComponent as ChevronDown } from '../../ui/icons/svg/chevron-down.svg';
import { ReactComponent as ChevronUp } from '../../ui/icons/svg/chevron-up.svg';
import Draggable from 'react-draggable';
import { Grid } from 'react-virtualized';
import Tooltip from '../../ui/Tooltip';

const SpreadsheetHeader = observer(function SpreadsheetHeader(props) {

    const screen = props.screen
    const orderedColumn = screen.ordered_column
    const columnCount = screen.visible_fields.length;
    

    function getColumnWidth({ index }) {
        switch (index) {
            case 0: return 20;
            default: return props.columnWidthMap.sizes[index] || 200;
        }
    }
    const onColumnClick = (e, column_name) => {
        //DISPATCH ORDER TO SCREEN
        screen.set_order(column_name)

    }
    function renderHeaderCell({ columnIndex, key, rowIndex, style }) {

        if (columnIndex < 1) {
            return;
        }

        let column = screen.visible_fields[columnIndex]
        const sortable = column.sortable
        let containerClass = "headerCell"


        return (
            <div className={containerClass} key={key} style={style}>

                <div className="headerCellContent" style={{ cursor: sortable ? "pointer" : "" }}>

                    <span onClick={sortable ? function (e) { onColumnClick(e, column.name) } : () => { }} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                        {column.tooltip ?
                            <Tooltip content={column.tooltip} type={column.tooltip_type}>{column.description}</Tooltip>
                            :
                            column.description
                        }
                    </span>
                    {sortable &&
                        <React.Fragment>
                            {orderedColumn.name === column.name &&
                                <React.Fragment>
                                    {orderedColumn.order === 'DESC' ?
                                        <ChevronDown style={{ width: '1em', height: '1em' }} className="fill-current text-xl cursor-pointer w-4 h-4 mr-1 md:mr-4 ml-2" /> : <ChevronUp style={{ width: '1em', height: '1em' }} className="fill-current text-xl cursor-pointer w-4 h-4 mr-1 md:mr-4 ml-2" />
                                        //   <FontAwesomeIcon style={{ paddingLeft: '2px' }} icon={faAngleDoubleDown} /> : <FontAwesomeIcon style={{ paddingLeft: '2px' }} icon={faAngleDoubleUp} />
                                    }
                                </React.Fragment>

                            }

                        </React.Fragment>
                    }
                    <Draggable
                        axis='x'
                        defaultClassName='DragHandle'
                        defaultClassNameDragging='DragHandleActive'

                        onStop={(event, data) => props.onResizeStop({

                            columnIndex: columnIndex,

                        })}

                        onDrag={(event, data) => props.resizeColumn({
                            deltaX: data.x,
                            columnIndex: columnIndex,
                            lastX: data.lastX,
                        })}
                        position={{
                            x: 0,
                            y: 0
                        }}
                        zIndex={999}
                    >
                        <div className='DragHandleIcon'>

                            ||
                  </div>
                    </Draggable>

                </div>



            </div>
        )

    }
    return (
        <Grid
            className="HeaderGrid"
            columnWidth={getColumnWidth}
            columnCount={columnCount}
            height={props.rowHeight}
            overscanColumnCount={0}
            cellRenderer={renderHeaderCell}
            rowHeight={props.rowHeight}
            rowCount={1}
            scrollLeft={props.scrollLeft}
            ref={props.gridHeaderRef}
            // width={width - scrollbarSize()}
            width={process.env.NODE_ENV === 'test' ? 900 : props.width}
        />

    )
})

export default SpreadsheetHeader