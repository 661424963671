import React from 'react';
// import {sheet_navigation_signals} from '../../common/common.js'
// import FocusTrap from 'focus-trap-react'
import { ReactComponent as Dots } from '../../ui/icons/svg/navigation-more.svg'
import { observer } from 'mobx-react-lite'

const base_cls = "bg-transparent focus:placeholder-transparent placeholder-gray-900  placeholder-opacity-50 focus:outline-none rounded-lg w-full appearance-none leading-normal"



const LookUpField = observer(function LookUpField(props) {
    const { field, state_attrs, record } = props;
    const value = field.get_value(record)
    const loading = value ? value.loading:true




    return (
<>
        {loading ? 
        <div className="flex justify-end content-end" style={{minHeight:'1.5rem'}}>
            <Dots style={{ width: '1.2em', height: '1.2em' }} className="fill-current text-xl cursor-pointer w-4 h-2 scale-left-to-right" />
            
        </div>
           
            :
            <input
            id={props.id || props.name}
            autoFocus={props.autoFocus}
            // tabIndex="-1"
            ref={props.inputRef}
            name={props.name}
            label={props.label}
            required={state_attrs.required}
            readOnly={true}
            className={base_cls}
            value={loading ? 'Loading...' : value}
            type={field.hide_value ? 'password' : ''}
            placeholder={field.description}
            style={props.style}
            autoComplete="off"

        />
        }
  </>      




    )

});


export default LookUpField;