import React from 'react';

import Select from 'react-select';



const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    borderRadius: '0px',
    marginTop: '0px'
  }),
  container: (provided, state) => ({

    ...provided,
    height:'100%',
    // backgroundColor: 'red',
    
    




  }),

  control: (provided, state) => ({

    ...provided,
    border: '0px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    // padding: '0px',
    minHeight: '0px',
    top:'10%'
    
    




  }),
  clearIndicator: (provided, state) => ({

    ...provided,
    cursor: 'pointer',
    paddingTop:0,
    paddingBottom:0,
    maxHeight:'1.5rem',
    ':hover': {

      color: 'red',
    },


  }),
  indicatorSeparator: (provided, state) => ({

    ...provided,
    cursor: 'pointer',
    margin:0,
    


  }),
  dropdownIndicator: (provided, state) => ({

    ...provided,
    cursor: 'pointer',
    paddingTop:0,
    paddingBottom:0,
    maxHeight:'1.5rem',
    


  }),
  input: (provided, state) => ({

    ...provided,
    margin:0,
    paddingTop:0,
    paddingBottom:0,
    maxHeight:'1.5rem',
    


  }),
  valueContainer: (provided, state) => ({

    ...provided,
    cursor: 'pointer',
    paddingTop:0,
    paddingBottom:0,
    paddingLeft:0,
    maxHeight:'1.5rem',
    
    
    


  }),
  placeholder: (provided, state) => ({

    ...provided,
    cursor: 'pointer',
    paddingTop:0,
    paddingBottom:0,
    maxHeight:'1.5rem',
    top:'40%'
    


  }),
  singleValue: (provided, state) => ({

    ...provided,
    cursor: 'pointer',
    padding:0,
    maxHeight:'1.5rem',
    overflow:'unset',
    top:'40%'
    
    


  }),
  
  menuPortal: (provided, state) => ({
    ...provided,
    zIndex: 9999,
    minWidth:200
  }),



}

function SelectField(props) {
  const {field, record, onBlur} = props
  const value = field.get_value(record)
  

    return (
        
        <Select
          isSearchable
          autoFocus={props.autoFocus}
          ref={props.inputRef}
          value={value}
          required={props.state_attrs.required}
          isDisabled={props.state_attrs.readonly}
          styles={customStyles}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          placeholder={field.description}
          onChange={(value)=>{
            props.onChange(false, value)
            onBlur(false,value)
          }}
          onKeyDown={props.handleKeyDown}
          options={field.selection_options}
          isMulti={props.multi}
          onBlur={props.saveOnBlur}
/>
            


    )

}

export default SelectField;