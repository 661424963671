import React from 'react';
import { AppContextProvider } from './model/context/AppContextProvider'
import { ConnectionProvider } from './connection/ConnectionProvider'
import { ThemeContextProvider } from './theme/ThemeContextProvider'
import { NotificationProvider } from './notifications/NotificationProvider'
export const AppRoot = ({ children, use_default_theme }) => {
    return (
        <NotificationProvider>
            <ConnectionProvider>
                <AppContextProvider>
                    <ThemeContextProvider use_default_theme={use_default_theme}>
                        {children}
                    </ThemeContextProvider>
                </AppContextProvider>
            </ConnectionProvider>
        </NotificationProvider>
    )
}

